<template>
  <div
    class="dot-testing px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent
      title="DOT/DoD Classification Testing of Energetic Substances and Articles"
      size="large"
    />
    <div class="flex flex-wrap pt-8">
      <div class="w-full md:w-1/3 mb-6 md:pr-2">
        <TitleComponent title="DOT Test Services" />
        <PanelGroupComponent :panels="panels" />
      </div>
      <div class="w-full md:w-2/3 mb-6 md:pl-2">
        <HeadingComponent
          title="SMS is a Premier Explosives Testing Lab Approved by the USDOT"
        />
        <Paragraph
          ><p>
            Safety Management Services, Inc. (SMS) provides US Department of
            Transportation (DOT) Competent Authority approval services for a
            wide range of clients in the explosive industry.
          </p>
          <p>
            Safety Management Services, Inc. is an&nbsp;<a
              class="link"
              href="https://www.phmsa.dot.gov/hazmat/energetic-materials-approvals/explosive-test-labs"
              target="_new"
              >approved and authorized Examining Agency</a
            >
            to perform explosives and other hazardous materials examination
            services. These services are for determining the DOT transportation
            classification, including the proper shipping name, hazard class and
            division, and compatibility group for explosive substances and
            articles.
          </p>
          <p>
            <router-link class="link" to="/resources/sms-team"
              >Kirt Sasser, Troy Gardner, Clint Guymon, and Jason
              Ford</router-link
            >
            are authorized to witness the UN/DOT tests and recommend
            classifications to the DOT. In addition to at least 10 years of
            explosive testing and witnessing experience, authorization by the
            DOT required a thorough examination of our explosive expertise,
            regulatory interpretation/application experience, and testing
            protocols.
          </p>
        </Paragraph>
        <HeadingComponent
          title="SMS Provides Witnessing and Recommended Classifications per CFR 173.56"
        />
        <Paragraph>
          <p>
            Consulting, witnessing, and providing recommendations for DOT
            Classifications are performed according to the requirements in the
            <a
              class="text-red-800"
              href="https://www.ecfr.gov/current/title-49/subtitle-B/chapter-I/subchapter-C/part-173/subpart-C/section-173.56"
              target="_blank"
              >Code of Federal Regulations: 173.56.</a
            >
            A proposal for testing the material(s) is provided after discussing
            the intent and extent of required testing. The cost of testing
            varies depending on the tests required, the nature of the
            material(s), and the test location. SMS can test your materials at
            <router-link class="link" to="/testing/test-site-at-tead"
              >our facilities</router-link
            >, or we can travel to your testing facility and witness testing.
          </p>
        </Paragraph>
        <HeadingComponent
          title="SMS Has Expertise Completing Testing for the DoD"
        />
        <Paragraph>
          <p>
            Safety Management Services, Inc. has completed testing with the US
            Department of Defense (DoD) and DoD contractors per TB 700-2 and
            applicable STANAG documents.
          </p>
          <p>
            Please contact us by using the chat window at the bottom right of
            the screen, calling 801-567-0456, or filling out the
            <router-link class="link" to="/contact">form here.</router-link>
          </p>
        </Paragraph>
      </div>
    </div>
    <div class="w-full mb-6">
      <div style="position: relative;padding-bottom: 56.25%;">
        <iframe
          src="https://player.vimeo.com/video/489988084"
          style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;border: 0;"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";
import HeadingComponent from "../../components/HeadingComponent";
import PanelGroupComponent from "@/components/PanelGroupComponent";

export default {
  metaInfo: {
    title: "DOT Testing",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS is an approved examining agency to authorize and witness UN Series/DOT tests and issue DOT shipping classifications."
      }
    ]
  },
  components: {
    TitleComponent,
    PanelGroupComponent,
    Paragraph,
    HeadingComponent
  },
  data: () => {
    return {
      panels: [
        {
          content1: `The `,
          contentLink: {
            text: "UN Transport acceptance procedures ",
            to: "/testing/dottesting/undot-acceptance-procedure"
          },
          content2: `are used to determine whether a new product, as offered for transport, is a candidate for Class 1, and if so to determine its shipping classification`,
          title: "UN DOT Acceptance Procedure"
        },
        {
          contentLink: {
            text: "Tests for Class 1",
            to: "/testing/in-process-simulation-testing"
          },
          content2: `are used to determine the shipping classification for substances or articles per the UN and the Code of Federal Regulations.`,
          title: "Tests for Class 1 (Explosives)"
        },
        {
          contentLink: {
            text: "Tests for Class 4",
            to: "/testing/dottesting/tests-for-class-4-flammable-solids"
          },
          content2: `are completed to determine if a substance is flammable, spontaneously combustible, or dangerous when wet.`,
          title: "Tests for Class 4 (Flammable Solids)"
        },
        {
          contentLink: {
            text: "Tests for Class 5",
            to: "/testing/dottesting/tests-for-class-5-oxidizing-materials"
          },
          content2: `are used to determine if a substance is an oxidizer.`,
          title: "Tests for Class 5 (Oxidizing Materials)"
        },
        {
          content1: "The ",
          contentLink: {
            text: "SMS test site",
            to: "/testing/test-site-at-tead"
          },
          content2: `is located at the Tooele Army Depot.`,
          title: "SMS Test Site"
        }
      ]
    };
  }
};
</script>

<style lang="scss">
.dot-testing {
  .link {
    color: #aa222c;
  }
  .group:hover {
    background-color: #aa222c;
    p {
      @apply text-white;
    }
  }
}
</style>
